import handleHrefClick from 'lib/handle-href-click';
import Link from 'next/link';
import { useEffect } from 'react';
import { ActiveCampaign } from './analytics/active-campaign';
import { FutyWidget } from './futy-widget';

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-3">
            <p className="menu-item">© 2022 Fysiotherapie4all</p>
          </div>
          <div className="col-12 col-lg-9">
            <ul className="menu-items">
              <li className="menu-item">
                <Link onClick={handleHrefClick} href="/algemene-voorwaarden">
                  Algemene voorwaarden
                </Link>
              </li>
              <li className="menu-item">
                <Link onClick={handleHrefClick} href="/vergoedingen">
                  Vergoedingen
                </Link>
              </li>
              <li className="menu-item">
                <Link onClick={handleHrefClick} href="/tarieven">
                  Tarieven
                </Link>
              </li>
              <li className="menu-item">
                <Link onClick={handleHrefClick} href="/disclaimers">
                  Disclaimers
                </Link>
              </li>
              <li className="menu-item">
                <Link onClick={handleHrefClick} href="/privacy-statement">
                  Privacy statement
                </Link>
              </li>
              <li className="menu-item">
                <Link onClick={handleHrefClick} href="/klachtenregeling">
                  Klachtenregeling
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <style jsx>{`
        footer {
          background-color: #153f66;
          position: relative;
        }

        ul {
          padding-inline-start: 0px;
        }

        footer ul.menu-items {
          text-align: left;
        }

        footer .container > .row {
          padding: 20px 0;
        }

        footer .menu-item {
          display: inline-block;
          margin-left: 20px;
        }

        footer .menu-item,
        footer .menu-item a {
          color: #ffffff;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 12px;
        }

        @media screen and (max-width: 543px) {
          footer ul.menu-items {
              text-align: left;
          }

          footer .menu-item {
              display: block;
              text-align: left;
              margin: 20px;
          }

          footer li.menu-item:first-child {
              margin-top: -20px;
          }
        }
      `}</style>

      <ActiveCampaign />
      <FutyWidget 
        disabled={process.env.NODE_ENV === 'development'}
      />
    </footer>
  )
}
