import Link from "next/link";
import { useRouter } from "next/router";

export default function RouterLink({
  href,
  children,
  ...props
}) {
  const router = useRouter();

  function handleHrefClick(e) {
    if(href){
      e.preventDefault();
      router.push(href);
    } else if (props.onClick) {
      props.onClick(e)
    }
  }

  return (
    <>
      {href && <Link {...props} href={href} onClick={handleHrefClick}>
        {children}
      </Link>}
      {!href && <a {...props} onClick={handleHrefClick}>{children}</a>}
    </>
  )
}
